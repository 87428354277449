//added sandbox environment
// const href = window.location.href;
// let url = "";
// if (href.includes("localhost")) url = process.env.REACT_APP_API_URL_DEV;
// else url = process.env.REACT_APP_API_URL;

export const urlBase = process.env.REACT_APP_API_URL;
export const wsUrlBase = process.env.REACT_APP_CHAT_URL;

export const API_ROUTES = {
  dockets: `${urlBase}/v1/docket/dockets`,
  jobs: `${urlBase}/v1/Jobs/getJob`,
  enterprise: {
    create: urlBase + "/v1/enterprise/createEnterprise",
    get: urlBase + "/v1/enterprise/getEnterprises",
    getByName: urlBase + "/v1/enterprise/getEnterprises?name=",
    delete: urlBase + "/v1/enterprise/deleteEnterprise?name=",
    edit: urlBase + "/v1/enterprise/updateEnterprise?name=",
  },
  workFlow: {
    root: urlBase + "/v1/workFlow",
    add: urlBase + "/v1/workFlow/add",
    execute: urlBase + "/v1/workFlow/execute",
    status: urlBase + "/v1/workFlow/status",
  },
  google: {
    auth: urlBase + "/v1/google/auth",
    validate: urlBase + "/v1/google/authValidate",
    driveFolder: urlBase + "/v1/google/driveFolder",
    uploadJob: urlBase + "/v1/google/uploadJob/",
    listFolders: urlBase + "/v1/google/listFolders",
  },
  notification: {
    root: urlBase + "/v1/user/notification",
  },
  approver: urlBase + "/v1/approver/user",
  invoiceApprove: urlBase + "/v1/jobs/approve",
  tallyConfiguration: urlBase + "/v1/tally/configuration",
  auth: {
    sendOTP: urlBase + "/v1/auth/sendOTP",
  },
  configuration: {
    all: urlBase + "/v1/configuration",
    tally: urlBase + "/v1/configuration/tally",
    genToken: urlBase + "/v1/configuration/genToken",
    list: urlBase + "/v1/configuration/list",
  },
  tally: {
    markPaid: urlBase + "/v1/tally/markPaid",
    getProducts: urlBase + "/v1/tally/getProducts",
    getSuppliers: urlBase + "/v1/tally/getSuppliers",
    getCompanies: urlBase + "/v1/tally/getCompanies",
    getVoucherTypes: urlBase + "/v1/tally/getVoucherTypes",
    getLedgers: urlBase + "/v1/tally/getLedgers",
    getGodowns: urlBase + "/v1/tally/getGodowns",
    getActiveCompany: urlBase + "/v1/tally/getActiveCompany",
    createLedger: urlBase + "/v1/tally/createLedger",
    getLedgerGroups: urlBase + "/v1/tally/getLedgerGroup",
  },
  job: {
    markPaid: urlBase + "/v1/jobs/markPaid",
    notifySupplier: urlBase + "/v1/jobs/notifySupplier",
    getFileById: urlBase + "/v1/jobs/getFileById",
    duplicateInvoices: urlBase + "/v1/jobs/checkDuplicateInvoices",
    submitByRow: urlBase + "/v1/jobs/submitByRow/",
    getJobs: urlBase + "/v1/jobs/getJobs",
    summary: urlBase + "/v1/jobs/summary",
    submitJob: urlBase + "/v1/jobs/submitJob",
    exportToExcel: urlBase + "/v1/jobs/exportToExcel",
    getFilebyPath: urlBase + "/v1/jobs/getJobFile",
    jobApprove: urlBase + "/v1/jobs/approveDocument",
  },
  stripe: {
    fetchDetails: urlBase + "/v1/stripe/fetchCurrentPlan",
  },
  download: {
    tally: urlBase + "/v1/tally/download",
  },
  user: {
    userGroup: urlBase + "/v1/users/userGroup",
    agreeToTerms: urlBase + "/v1/users/agreeToTerms",
  },
  extract: {
    bulkUpload: urlBase + "/v1/extract/bulkUpload",
    upload: urlBase + "/v1/extract",
    website: urlBase + "/v1/extract/website/",
    other: urlBase + "/v1/extract/other",
    getResult: urlBase + "/v1/extract/result",
  },
  dashboard: {
    enterprise_usages: urlBase + "/v1/dashboard/getlicense",
    statistics: urlBase + "/v1/dashboard/system/statistics",
    usageByOrganizations: urlBase + "/v1/dashboard/system/usageByOrganizations",
    summary: urlBase + "/v1/dashboard/summary",
    jobs: urlBase + "/v1/dashboard/jobs",
  },
  ticket: {
    category: urlBase + "/v1/ticket/category",
    raiseTicket: urlBase + "/v1/ticket/raise_ticket",
    listTickets: urlBase + "/v1/ticket/list_ticket",
  },
  classification: {
    invoice: urlBase + "/v1/classification/invoice",
  },
  quickbook: {
    auth: urlBase + "/v1/quickbook/auth",
    enabled: urlBase + "/v1/configuration/list",
    getCategory: urlBase + "/v1/Quickbook/getItemList",
    getVendor: urlBase + "/v1/Quickbook/getVendorList",
    getExpense: urlBase + "/v1/Quickbook/listExpenseAcc",
    signIn: urlBase + "/v1/configuration/quickbook",
    signOut: urlBase + "/v1/quickbook/signout",
    getTaxCodes: urlBase + "/v1/Quickbook/listTaxCodes",
    getCompanies: urlBase + "/v1/Quickbook/getCompany",
  },
  zohobook: {
    auth: urlBase + "/v1/zohobook/auth",
    signIn: urlBase + "/v1/configuration/list",
    signOut: urlBase + "/v1/zohobook/zohobookSignout",
    getCompanies: urlBase + "/v1/zohobook/getCompanyList",
    getVendor: (orgId) => `${urlBase}/v1/zohobook/getVendorList/${orgId}`,
    getCategory: (orgId) => `${urlBase}/v1/zohobook/getItemList/${orgId}`,
    getTaxCodes: (orgId) => `${urlBase}/v1/zohobook/getTaxList/${orgId}`,
    getAccounts: (orgId) => `${urlBase}/v1/zohobook/getAccountList/${orgId}`,
    getLedgers: (orgId) => `${urlBase}/v1/zohobook/getTdsTcs/${orgId}`,
    getBankAccounts: (orgId) =>
      `${urlBase}/v1/zohobook/zohobookBankAcccountList/${orgId}`,
    getTransactionOptions: (orgId) =>
      `${urlBase}/v1/zohobook/getTransactionOptions/${orgId}`,
  },
  metaData: {
    docTypes: urlBase + "/v1/metadata/docTypes",
    get: urlBase + "/v1/metadata/get",
  },
  docket: {
    get: urlBase + "/v1/docket",
    getDockets: urlBase + "/v1/docket",
    create: urlBase + "/v1/docket",
    getStatements: urlBase + "/v1/docket/getStatements",
    uploadStatement: urlBase + "/v1/docket",
    approve: urlBase + "/v1/docket/approve",
    getBankAnalytics: urlBase + "/v1/docket/statementAnalytics",
    getSummary: urlBase + "/v1/docket/summary",
    webSearch: urlBase + "/v1/docket/websearch",
    getWebsiteData: urlBase + "/v1/docket/getWebsiteData",
    comments: urlBase + "/v1/docket/comments",
    submitTable: urlBase + "/v1/docket/store-table",
  },
  doclibrary: {
    get: urlBase + "/v1/doc-library",
    create: urlBase + "/v1/doc-library",
    createChat: urlBase + "/v1/doc-library/chatbot",
    getChat: urlBase + "/v1/doc-library/chatbot/",
  },
  exchagerate: {
    getRates: urlBase + "/v1/exchangeRate/rates",
    conversion: urlBase + "/v1/exchangeRate/conversion",
  },
  kyc: {
    createFolder: urlBase + "/v1/kyc",
    getFolders: urlBase + "/v1/kyc",
    getFolderById: urlBase + "/v1/kyc/",
    deleteFolder: urlBase + "/v1/kyc/",
    uploadFiles: urlBase + "/v1/kyc/",
    getSummary: urlBase + "/v1/kyc/summary",
  },
  export: {
    htmlToPdf: urlBase + "/v1/export/html-to-pdf",
    jsonToDocx: urlBase + "/v1/export/json-to-docx",
    toExcel: urlBase + "/v1/export/toExcel",
  },
  admin: {
    getTenantUsage: urlBase + "/v1/admin/tenant",
    updatedSubscription: urlBase + "/v1/admin/tenant/subscription",
    rejectedJobs: urlBase + "/v1/admin/tenant/rejectedJobs",
    getFile: urlBase + "/v1/admin/tenant/file",
    getJob: urlBase + "/v1/admin/tenant/job",
  },
};

export const getZohoServiceAPI = (method, scopes = null) => {
  if (!scopes) {
    return API_ROUTES.zohobook[method];
  }
  return API_ROUTES.zohobook[method] + "?&scopes=" + scopes;
};

export const getGoogleServiceAPI = (method, scopes = null) => {
  if (!scopes) {
    return API_ROUTES.google[method];
  }
  return API_ROUTES.google[method] + "?&scopes=" + scopes;
};

export default function getURL(urlKey) {
  return API_ROUTES[urlKey];
}

export const associateLabelURL = (apiName) => {
  const label = {
    update: urlBase + "/v1/labels/associateLabel",
  };
  return label[apiName];
};

export const getlabelURL = (apiName) => {
  const labels = {
    get: urlBase + "/v1/labels/getLabels",
    create: urlBase + "/v1/labels/createLabel",
    delete: urlBase + "/v1/labels/deleteLabel",
    update: urlBase + "/v1/labels/updateLabel",
  };
  return labels[apiName];
};

export const getJobUrl = (apiName, id, enterpriseId, dicketId, engine) => {
  const job = {
    get: urlBase + "/v1/jobs/getJob/" + id,
    update: urlBase + "/v1/jobs/getJob/" + id,
    getJobFile: urlBase + "/v1/jobs/getJobFile?file=",
    fileUpload:
      urlBase +
      `/V1/docket/${enterpriseId}/dockets/${dicketId}/files?engine=${engine ?? "amazon"}`,
  };
  return job[apiName];
};

export const getDocketURL = (enterpriseId, apiName, docketId, fileId) => {
  const dockets = {
    create: urlBase + `/V1/docket/${enterpriseId}/dockets`,
    get: urlBase + `/V1/docket/${enterpriseId}/dockets`,
    delete: urlBase + `/V1/docket/${enterpriseId}/dockets/`,
    update: urlBase + `/V1/docket/${enterpriseId}/dockets/`,
    download: urlBase + `/V1/docket/${enterpriseId}/dockets`,
    downloadSingleDocketFile:
      urlBase + `/V1/docket/${enterpriseId}/dockets/${docketId}/files/${fileId}`,
    updateFileName: urlBase + `/v1/Jobs/getJob`,
    files: urlBase + `/v1/docket/${enterpriseId}/dockets/${docketId}/files`,
  };
  return dockets[apiName];
};

export const getRoleURl = (apiName) => {
  const role = {
    create: urlBase + `/V1/roles/`,
    get: urlBase + `/V1/roles/`,
    delete: urlBase + `/V1/roles/`,
  };
  return role[apiName];
};

export const getUserURl = (apiName) => {
  const user = {
    create: urlBase + `/V1/users/`,
    get: urlBase + `/V1/users/`,
    delete: urlBase + `/V1/users/`,
    agreeToTerms: urlBase + `/v1/users/agreeToTerms`,
  };
  return user[apiName];
};

export const getNotificationURl = (apiName, enterpriseId, docketId) => {
  const notification = {
    create: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/create`,
    get: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/get`,
    update: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/update`,
    delete: urlBase + `/V1/notifications/${enterpriseId}/${docketId}/delete`,
    validation: urlBase + `/v1/csnotifications/getcsNotifications`,
    updateSerialNum: urlBase + `/v1/csnotifications/`,
  };
  return notification[apiName];
};

export const getMetadataURL = (apiName) => {
  const route = {
    getCurrency: urlBase + `/v1/metadata/currencyList`,
    getUOM: urlBase + `/v1/metadata/getUOM`,
    getDropdownLists: urlBase + `/v1/metadata/getDropdownLists`,
  };
  return route[apiName];
};

export const getChatURL = (apiName) => {
  const route = {
    docketChat: wsUrlBase + `/v1/docket/chat`,
    docLibrary: wsUrlBase + `/v1/doc-library/chat/`,
  };
  return route[apiName];
};
